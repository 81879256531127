import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import tw from 'twin.macro'

import { HeaderStyle, CopyStyle } from './UI'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import '../styles/main.css'

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const Clients = ({ clients, hospitality }) => {
  if (hospitality) {
    clients = clients.filter((x) => {
      if (x?.logo?.filename) {
        console.log(x?.logo?.filename)
        return (
          x.logo.filename !== 'Canaccord Genuity Corp.png' &&
          x.logo.filename !== 'The wine acadamy.png'
        );
      }
      return true;
    });
  }

  return (
    <>
      {clients && (
        <section tw="bg-[#FFFFFF]">
          <div tw="mx-7 py-14 lg:(max-w-screen-lg mx-auto py-20)">
            {/* Headline */}
            <h2 css={[HeaderStyle, tw`mb-7 lg:(text-4xl)`]}>Our clients</h2>
            {/* Copy */}
            <p css={[CopyStyle, tw`mb-12`]}>
              {hospitality ? `We're privileged to support leading hospitality organizations across North America. From major restaurant chains to leading nightlife venues, our Staffy commitment is to ensure you’re equipped with top-tier talent when you need it, making us the trusted staffing choice for industry leaders.`
                : `We're privileged to support leading healthcare organizations across North America. From major hospitals to specialized clinics, our Staffy commitment is to ensure you’re equipped with top-tier talent when you need it, making us the trusted staffing choice for industry leaders.`}
            </p>
            {/* Logos */}
            {/* <div tw=''>
              <Carousel responsive={responsive} tw=''>
                {clients.map((client, index) => (
                  <div
                    tw=""
                    key={`client-${index}`}
                  >
                    <GatsbyImage
                      image={client.logo.gatsbyImageData}
                      alt={client.name}
                    />
                  </div>
                ))}
              </Carousel>
            </div> */}
            <div tw="flex flex-wrap justify-around sm:justify-between items-center">
              {clients.map((client, index) => (
                <div
                  tw="px-4 my-2 w-1/2 lg:(flex-1 my-0)"
                  key={`client-${index}`}
                >
                  <GatsbyImage
                    tw="mb-5 flex-shrink"
                    image={client.logo.gatsbyImageData}
                    alt={client.name}
                  />
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default Clients
